













import {Vue, Component} from "vue-property-decorator";
import SecondCommentType from "./SecondComment";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import CommentShow from "@/views/Group/components/Comment/index.vue"
import SecondCommentCont from "@/views/Group/SecondComment/components/Content/index.vue";
import SecondCommentFooter from "@/views/Group/SecondComment/components/Footer/index.vue";

@Component({name: "SecondComment",components:{ HeadTop,SecondCommentCont,CommentShow,SecondCommentFooter }})
export default class SecondComment extends ZoomPage implements SecondCommentType{
    List:any[] = []

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { taskId,data } = this.$route.query
        let JsonData = JSON.parse( data as string )
        //
    }

    handleGetList(e:any[]){
        this.List = e
    }
}
