


















import {Vue, Component, Watch, Emit} from "vue-property-decorator";
import SecondCommentFooterType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import SecondCommentFooterItem from "@/views/Group/SecondComment/components/FooterItem/index.vue";
import {getGroupSecond} from "@/views/Group/SecondComment/Server";
import {SecondCommentType} from "@/Api/Group/indexType";
import {IndexStore} from "@/store/modules/Index";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";


@Component({name: "SecondCommentFooter",components:{ PullDownUpList,ListBottom,SecondCommentFooterItem }})
export default class SecondCommentFooter extends Vue implements SecondCommentFooterType{
    PullDown = false;
    UpDownBool = true;
    upData: SecondCommentType = {
        pageNo:1,
        pageSize:15,
        firstCommentId:"",
        taskId:""
    };
    List:any[] = []
    StopUp = false

    Forbid = true
    scrollHeight = 0
    minHeight = 20
    app:any = {}

    activated(){
        let { taskId,data } = this.$route.query
        let JsonData = JSON.parse( data as string )
        this.upData = {
            ...this.upData,
            pageNo:1,
            taskId:(taskId || '') as string,
            firstCommentId:JsonData.id
        }
        this.handleUpData(true)
    }

    init(){
        let app:any = document.getElementById("app");
        this.app = app
        let boxHeight = app.offsetHeight,
            contentHeight = app.scrollHeight;
        if ( contentHeight > boxHeight ){
            this.scrollHeight = contentHeight - boxHeight
            app.addEventListener("scroll",this.handleScroll,{ passive: false })
        }else{
            app.removeEventListener("scroll",this.handleScroll,{ passive: false })
        }
    }

    handleScroll(e:any){
        let { scrollTop } = e.target
        this.init()
        if ( scrollTop >= (this.scrollHeight - this.minHeight) ){
            this.Forbid = false
            this.app.scrollTop = scrollTop
            IndexStore.SetScrollStopBool(true)
        }else{
            this.Forbid = true
            IndexStore.SetScrollStopBool(false)
        }
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    handleFilterList(index:number){
        this.List.splice(index,1)
    }

    handlePullDown(){
        //
    }

    handleUpDown(){
        if ( this.StopUp )return;
        this.upData.pageNo += 1
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false): void {
        getGroupSecond(this.upData).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < this.upData.pageSize )this.StopUp = true;
            if ( bool )
                this.List = res;
            else
                this.List = this.List.concat(res);
        })
    }

    get getRefresh(){
        return GroupSucStore.getGroupSucCommentListRefresh
    }
    @Watch("getRefresh")
    changeRefresh(newVal:boolean){
        if ( newVal ){
            this.upData.pageNo = 1
            this.UpDownBool = true
            this.handleUpData(true)
        }
    }

    @Emit("changeAndPull")
    @Watch("List")
    changeAndPull(newVal:any[]){
        return newVal
    }
}
