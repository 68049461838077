











































import {Vue, Component, Prop, Watch, Emit} from "vue-property-decorator";
import SecondCommentFooterItemType from "./indexType";
import Storage from "@/util/Storage";
import {computedTime} from "@/util/computedTime";
import {delComment} from "@/views/Group/SecondComment/Server";
import {setColl} from "@/views/Group/Server";

@Component({name: "SecondCommentFooterItem"})
export default class SecondCommentFooterItem extends Vue implements SecondCommentFooterItemType{
    CartIcon = require("../../../Img/comment.png");
    GiveIcon = require("../../../Img/A_coll.png");
    NotGiveIcon = require("../../../Img/coll.png");
    DelIcon = require("../../../Img/del.png")
    active = false
    userId = ""
    commentData:any = {}

    mounted() {
        this.userId = Storage.GetData_ && Storage.GetData_("userId")
        let { data } = this.$route.query
        let JsonData = JSON.parse( data as string )
        this.commentData = JsonData
        this.init()
    }

    init() {
        if (this.getData && Number(this.getData.isLike)) {
            this.active = true
        } else {
            this.active = false
        }
    }

    handleRemoveComment() {
        delComment( this.getData && this.getData.id ).then(res=>{
            this.pullIndex()
        })
    }

    handleCollect(){
        setColl(1,(this.getData.taskId || ''),this.getData.id).then(res=>{
            this.active = !this.active
        })
    }

    get getPhoto() {
        return this.getData && this.getData.heanImg || require("@/assets/icon/Basics/default.png")
    }

    get getTime() {
        return computedTime(this.getData && this.getData.createTime )
    }

    get removeShow() {
        if (this.getData && this.getData.userId == this.userId) {
            return true
        } else {
            return false
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }@Watch("getData")
    changeData(newVal: any) {
        this.init()
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }

    @Emit("pullIndex")
    pullIndex(){
        return this.index
    }
}
