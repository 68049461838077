












































import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import SecondCommentContType from "./indexType";
import {computedTime} from "@/util/computedTime";
import Storage from "@/util/Storage"
import {delComment} from "@/views/Group/SecondComment/Server";
import {setColl} from "@/views/Group/Server";
import {GroupSucStore} from "@/views/Group/GroupSuc/Store";

@Component({name: "SecondCommentCont"})
export default class SecondCommentCont extends Vue implements SecondCommentContType{
    DelIcon = require("@/views/Group/Img/del.png")
    CartIcon = require("@/views/Group/Img/comment.png")
    CollIcon = require("@/views/Group/Img/coll.png")
    A_CollIcon = require("@/views/Group/Img/A_coll.png")
    active = false
    data:any = {}

    activated(){
        let { taskId,data } = this.$route.query
        let JsonData = JSON.parse( data as string )
        console.log( JsonData )
        this.data = JsonData
    }

    handleRemove(){
        delComment(this.getData && this.getData.id ).then(res=>{
            this.$router.go(-1)
        })
    }

    handleComment(){
        let commentData = GroupSucStore.getGroupSucCommentData;
        commentData.taskId = ( this.$route.query.taskId || 0 ) as string
        commentData.toId = this.getData && this.getData.userId
        commentData.firstCommentId = this.getData && this.getData.id
        GroupSucStore.SetGroupSucCommentData(commentData)
        GroupSucStore.SetGroupSucCommentShow(true)
    }

    handleCollect(){
        setColl(1,this.getData && this.getData.taskId,this.getData && this.getData.id).then(res=>{
            this.active = !this.active
        })
    }

    get getPhoto(){
        return this.getData && this.getData.heanImg || require("@/assets/icon/Basics/default.png")
    }

    get getTime(){
        return computedTime(this.getData && this.getData.createTime )
    }

    // @Prop(Object)
    // data!:any
    get getData(){ return this.data }
    @Watch("getData")
    changeData(newVal:any){
        if ( this.getData && this.getData.isCollect == 1 ){
            this.active = true
        }else{
            this.active = false
        }
    }

    get commentRemoveShow(){
        const userId = Storage.GetData_ && Storage.GetData_("userId")
        if ( this.getData ){
            if ( this.getData && this.getData.userId == userId ){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
}
